import React from "react";
import TropicalImage from "../../Images/tropical-1.jpg";
import TropicalImageTwo from "../../Images/tropical-2.jpg";
import ManImage1 from "../../Images/man-image-1.png";
import ManImage2 from "../../Images/man-image-2.png";
import Exploremanone from "../../Images/explore-man-one.png";
import Exploremantwo from "../../Images/explore-man-two.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



function ExploreMen() {
  return (
    <div className="container ">
        <h2 className="py-3  explore fw-bold Cormorant-font">Explore Men</h2>
        <div className="row image-container-hover-all">
          <div className="col-md-6">
          <div className="image-container-all">
            <LazyLoadImage effect="blur" className="img-fluid" src={Exploremanone} alt="" />
            </div>
            <h4 className="font-size my-2 custom-pb-five">Regal Embroidered Kurta Set</h4>
          </div>
          <div className="col-md-6">
          <div className="image-container-all">
            <LazyLoadImage effect="blur" className="img-fluid" src={Exploremantwo} alt="" />
          </div>
          <h4 className=" my-2 custom-pb-five font-size">Sunset Serenade Artistic Blazer</h4>
          </div>
        </div>
    </div>
  );
}

export default ExploreMen;
